import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['sidenav']

  connect() {
    this.toggleClass = this.data.get('class') || 'hidden'
  }

  toggle() {
    if (this.sidenavTarget.classList.contains(this.toggleClass)) {
      this.showNav()
    } else {
      this.hideNav()
    }
  }

  showNav() {
    this.sidenavTarget.classList.remove(this.toggleClass)
  }

  hideNav() {
    this.sidenavTarget.classList.add(this.toggleClass)
  }
}

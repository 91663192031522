import { Controller } from "stimulus"
import debounce from 'lodash.debounce';
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [ "input", "hidden", "results", "template", "items" ]

  initialize() {
    this.search = debounce(this.search, 250)
  }

  connect() {
    this.inputTarget.focus()
  }
  
  search() {
    if (this.inputTarget.value.length == 13 || this.inputTarget.value.length == 10) {
      this.fetchSearchResults(this)
    }
  }

  submit_item(event) {
    Rails.fire(event.currentTarget.closest('form'), 'submit')
    this.resultsTarget.innerHTML = ""
    this.inputTarget.value = ""
    this.inputTarget.focus()
  }

  renderSearchResults(results) {
    this.resultsTarget.innerHTML = results.body.innerHTML
    var li = this.resultsTarget.querySelector("li.cursor-pointer > a")

    li.focus()
  }

  fetchSearchResults(context) {
    Rails.ajax({
      type: "GET", 
      url: this.data.get('searchUrl'),
      data: `q=${this.inputTarget.value}`,
      success: function(response){
        context.renderSearchResults(response)
      },
      error: function(response){
        console.log(response)
      }
    })
  }
}
import { Controller } from 'stimulus';
import consumer from '../channels/consumer';

export default class extends Controller {
  static targets = [ "progress" ]
  initialize() {
  }

  connect() {
    this.initChannel(this)
  }

  renderOutput(results) {
    this.progressTarget.innerHTML = results.imported_prices
  }

  initChannel(context) {
    consumer.subscriptions.create(
      { 
        channel: 'PriceListChannel',
        id: this.data.get("id"),
      }, {
      connected() {
      },  
      received(data) {
        context.renderOutput(data)
      },
    });
  }
}

  
// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import Papa from 'papaparse';

export default class extends Controller {
  static targets = [ "file", "message", "preview", "header", "table", "output", "hint", "save", "source" ]

  connect() {
  }

  preview() {
    this.previewTarget.remove()

    var csvFile = this.fileTarget.files[0]

    this.headerTarget.textContent = csvFile.name

    this.outputTarget.classList.remove("hidden")
    this.hintTarget.classList.remove("hidden")
    this.saveTarget.classList.add("attention")

    this.parseCSV(csvFile, this)
  }

  renderPreview(results) {
    var table = document.createElement('table')
    var tableBody = document.createElement('tbody')
    var headers = Object.values(JSON.parse(this.data.get("mappings"))[this.sourceTarget.value])
    var tableHead = document.createElement('thead')

    var header_row = document.createElement('tr')
    headers.forEach(function(header) {
      var cell = document.createElement('th')
      cell.appendChild(document.createTextNode(header))

      cell.classList.add("py-4", "px-6", "bg-gray-lightest", "font-bold", "uppercase", "text-sm", "text-gray-dark", "border-b", "border-gray-light")

      header_row.appendChild(cell)
    })

    tableHead.appendChild(header_row)

    results.data.forEach(function(rowData) {
      var row = document.createElement('tr')

      console.log(rowData)

      headers.forEach(function(header) {
        var cell = document.createElement('td')
        cell.appendChild(document.createTextNode(rowData[header.toUpperCase()]))

        cell.classList.add("py-4", "px-6", "border-b", "border-gray-light")

        row.appendChild(cell)
      })

      tableBody.appendChild(row)
    })

    table.classList.add("text-left", "w-full", "border-collapse")

    table.appendChild(tableHead)
    table.appendChild(tableBody)
    this.tableTarget.appendChild(table)
  }

  parseCSV(uploadedFile, context) {
    Papa.parse(uploadedFile, {
      header: true,
      preview: 5,
      transformHeader: function(header) {
        return header.trim().toUpperCase().replace(" ", "_")
      },
      transform: function(string, header) {
        var string = string.trim()
        if (/isbn|asin/i.test(header)) {
          return string.replace(/-/g, "")
        } else {
          return string
        }
      },
      complete: function(results, file) {
        context.renderPreview(results)
      }
    })
  }
}
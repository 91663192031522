import * as jsPDF from 'jspdf'
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "content" ]

  connect() {
    console.log('Label Printer')
  }

  generatePdf() {
        var doc = new jsPDF('l', 'mm', [28.575 * 5.8, 88.9 * 5.8]);
        doc.setFont("arial");
        var specialElementHandlers = {
            '#editor': function (element, renderer) {
                return true;
            }
        };
        var contentContainer = document.querySelector('#content');
        doc.fromHTML(contentContainer.outerHTML, 5, -5, {
            'width': 170,
                'elementHandlers': specialElementHandlers
        });
        doc.autoPrint();
        doc.save('labels.pdf');
  }
}